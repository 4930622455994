@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */

@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';
@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */

@layer utilities {
    .rtl {
        direction: rtl;
    }
}


/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */

[x-cloak=""] {
    display: none;
}

landing-cover {
    height: 100%;
}

landing-cover {
    background: url(https://fullmovil.com.co/wp-content/uploads/2021/11/Full-movil-banner-home-3.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/*======================MEGA MENU================================*/
nav .container {
    width: 1140px;
    margin: 0 auto;
  }
  
  nav .container .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  .logo a {
    font-size: 20px;
    font-weight: 700;
    color: #353535;
    text-transform: uppercase;
  }
  
  .desktop {
      display: block;
  }
  .mobile {
      display: none;
  }
  
  /* normal menu css */
  
  .main_menu > ul > li {
    display: inline-block;
    position: relative;
    margin: 0 -2px;
  }
  .main_menu ul li {
    position: relative;
    text-align: left;
    margin-left: 5px;
  }
  
  .main_menu ul li a {
    font-size: 16px;
    color: #3b444f;
    padding: 20px 25px;
    display: block;
    font-weight: 400;
  }
  
  .main_menu ul li .active,
  .main_menu ul li:hover > a {
    color: #3b358b;
    text-decoration: none;
  }
  /* Normal Dropdown menu */
  .main_menu ul li ul {
    width: 250px;
    background: #fff;
    transition: 0.5s;
    /*box-shadow: 0px 5px 15px 0px rgba(212, 201, 201, 0.75);*/
  }
  
  .main_menu ul li ul li a {
    padding: 10px 5px;
    font-size: 15px;
  }
  .main_menu ul li ul li a i {
    float: right;
  }
  
  .main_menu ul li ul li ul {
    left: 100%;
    top: 0;
  }
  
  /* mega menu css */
  .mega_menu_dropdown {
    position: static !important;
  }
  .mega_menu {
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    transition: 0.2s;
    /* box-shadow: 0px 5px 15px 0px rgba(212, 201, 201, 0.75);*/
  }
  .mega_menu_item {
    width: 16%;
    padding: 20px 10px;
    text-align: left;
  }
  .main_menu ul li .mega_menu_item a {
    padding: 5px 0;
  }
  
  .main_menu ul li .mega_menu_item a:hover {
    color: #3b358b;
    text-decoration: none;
  }
  .mega_menu_item h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #3b358b;
    text-decoration: none;
  }
  .mega_menu_item img {
    width: 100%;
  }
  
  /* demo_2 css */
  .mega_menu_demo_2 .mega_menu {
    left: 50%;
    transform: translateX(-50%);
    width: 1140px;
  }
  
  .mobile_btn {
    display: none;
  }
  
  /* responsive css */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  nav .container {
      width: 960px;
    }
    .mega_menu_demo_2 .mega_menu {
      width: 940px;
    }
    .main_menu ul li ul {
      width: 150px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
  nav .container {
      width: 720px;
    }
    .mega_menu_demo_2 .mega_menu {
      width: 700px;
    }
    .main_menu ul li a {
      font-size: 15px;
      padding: 20px 16px;
    }
    .main_menu ul li ul {
      width: 150px;
    }
  }
  @media (min-width: 768px) {
    .main_menu ul li ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      margin-top: 50px;
    }
    .main_menu ul li .mega_menu {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      margin-top: 30px;
    }
    .main_menu ul li:hover > ul {
      visibility: visible;
      opacity: 1;
      margin-top: 0px;
      z-index: 99;
    }
    .main_menu ul li:hover > .mega_menu {
      visibility: visible;
      opacity: 1;
      margin-top: 0;
      z-index: 99;
    }
  }
  
  @media (max-width: 767.98px) {
    .mega_menu_demo_2 .mega_menu,
    nav .container {
      width: 100%;
    }
  
    nav {
      padding: 15px;
    }
    
    .mobile_btn {
      cursor: pointer;
      display: block;
      background-color: #3b358b !important;
      color: #ffffff;
      padding: 0;
      background: 0 0;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 10%;
      border: 3px solid #3b358b;
      margin: 45px 15px 0 auto;
      position: relative;
      top: -27px;
    }
    
    .mobile_btn svg {
        width: 32px;
        padding: 2px;
        color: #ffffff;
    }
    
    .mobile_btn i {
        padding: 8px;
    }
  
    .main_menu {
      display: none;
      width: 100%;
    }
  
    .main_menu ul li {
      display: block;
    }
    .main_menu ul li a i {
      float: right;
    }
    .main_menu ul li a {
      border-bottom: 1px solid #ddd;
      padding: 2px 5px;
    }
    .main_menu ul li ul {
      width: 100%;
    }
    .main_menu ul li ul li ul {
      left: 0;
      top: auto;
    }
  
    .mega_menu .mega_menu_item {
      width: 50%;
    }
    .mega_menu .mega_menu_item img {
      display: none;
    }
    .main_menu ul li ul {
      display: none;
      transition: none;
    }
    
    /*.main_menu ul li:hover ul {
      display: block;
    }*/
    
    .main_menu ul li .mega_menu {
      display: none;
      transition: none;
    }
    
    /*.main_menu ul li:hover .mega_menu .mobile {
      display: block;
    }*/
  
    .mega_menu_demo_2 .mega_menu {
      transform: translateX(0);
    }
  }
  
  @media (max-width: 575.98px) {
    .mega_menu .mega_menu_item {
      width: 100%;
    }
    
  }