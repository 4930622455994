.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-container {
    margin: 10px 20px 0 0;
    border: 5px solid #333;
    overflow: auto
}

.item-thumb {
    float: left
}

.item-thumb img {
    display: block;
    width: 150px;
    height: auto
}

.item-content {
    margin-left: 10px
}

.item-title {
    font-weight: bold;
    font-size: 200%
}

.card {
    align-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    height: 0%;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 20px;
    overflow: hidden;
}

.card img {
    display: block;
    width: 90px;
    height: auto
}

.card-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.list-group {
    max-height: 70vh;
    margin-bottom: 1px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.progress-bar div {
    width: 25%;
    height: 100%;
    background-color: rgb(98, 0, 255);
}

.background-overlay {
    background: url(https://fullmovil.com.co/wp-content/uploads/2021/11/Full-movil-banner-home-3.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}